import request from '@/plugins/http'

export function getGoodsCategoryList(param) {
    return request.post('/api/goods/getGoodsCategoryList', param);
}

export function getGoodsCategoryMainNo() {
    return request.post('/api/goods/getGoodsCategoryMainNo');
}

export function saveGoodsCategory(param) {
    return request.post('/api/goods/saveGoodsCategory', param);
}

export function deleteGoodsCategory(param) {
    return request.post('/api/goods/deleteGoodsCategory', param);
}

export function validGoodsCategory(param) {
    return request.post('/api/goods/validGoodsCategory', param);
}

export function invalidGoodsCategory(param) {
    return request.post('/api/goods/invalidGoodsCategory', param);
}

export function getCategoryGoods(param) {
    return request.post('/api/goods/getCategoryGoods', param);
}

export function bindCategoryGoods(param) {
    return request.post('/api/goods/bindCategoryGoods', param);
}

export function untieCategoryGoods(param) {
    return request.post('/api/goods/untieCategoryGoods', param);
}

export function remoteGoodsCategory(param) {
    return request.post('/api/goods/remoteGoodsCategory', param);
}

export function refreshLinkGoodsNum(param) {
    return request.post('/api/goods/refreshLinkGoodsNum', param);
}

export function apiSaveGoodsFast(param) {
    return request.post('/api/goods/saveGoodsFast', param);
}




















