<template>
  <div>
    <!--搜索-->
    <Modal id="selected_goods" v-model="add_goods.modal" title="商品选择" width="1080" @on-ok="addGoodsSetSelectedGoods">
      <Form ref="add_goods.formItem" :model="add_goods.formItem" inline>
        <FormItem prop="part_no">
          <i-input @on-blur="searchGoodsSubmit()" v-model="add_goods.formItem.part_no" class="w-200" placeholder="请填写商品编号"/>
        </FormItem>

        <FormItem prop="part_name">
          <i-input @on-blur="searchGoodsSubmit()" v-model="add_goods.formItem.part_name" class="w-200" placeholder="请填写商品名称"/>
        </FormItem>

        <FormItem prop="category_id">
          <Select @on-change="searchGoodsSubmit()" v-model="add_goods.formItem.category_id" class="w-200" filterable clearable placeholder="请搜索选择商品类别"
                  :remote-method="remoteGoodsCategory" :loading="common.category_loading">
            <Option v-for="(option, index) in common.category_options" :value="option.value" :key="index">{{option.label}}</Option>
          </Select>
        </FormItem>

        <FormItem>&nbsp;
          <Button type="text" class="main-font-color" @click="cancelGoodsSearch('add_goods.formItem')">
            清除
          </Button>
        </FormItem>

        <Poptip width="600">
          <Avatar class="pointer main-background-color">{{ add_goods.selected.length }}</Avatar>
          <div slot="title"><strong>选中商品</strong></div>
          <div class="api" slot="content">
            <Table :columns="add_goods.selected_columns" :data="add_goods.selected" max-height="470">
              <template slot-scope="{ row,index }" slot="part_no">
                <span>({{ row.part_no }}) {{ row.part_name }}</span>
              </template>
              <template slot-scope="{ row,index }" slot="action">
               <span @click="selectedClose(row, index)" class="selected-close main-color">
                 <Icon type="md-close"/>
               </span>
              </template>
            </Table>
          </div>
        </Poptip>

        <FormItem>&nbsp;
          <Icon type="md-add" class="ml-20 main-font-color fw-bold pointer" @click="addGoodsFast('add_fast')"/>
        </FormItem>
      </Form>

      <Row class="display-block">
        <!--商品列表-->
        <Table :loading="add_goods.list_loading" ref="add_goods_list" :columns="add_goods.columns" :data="add_goods.list"
               @on-selection-change="addGoodsGetGoodsInfo" size="small" @on-row-click="rowClick">
          <!--        <template slot-scope="{ row }" slot="thumbnail_url">-->
          <!--          <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>-->
          <!--        </template>-->
          <template slot-scope="{ row }" slot="stock_all">
            <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
          </template>
          <template slot-scope="{ row }" slot="part_no">
            <strong>{{ row.part_no }}</strong>
          </template>
        </Table>
      </Row>
      <Row style="flex-flow:row-reverse wrap">
        <Page size="small" :total="add_goods.list_total" :current.sync="add_goods.current_page"
              :page-size="add_goods.page_size" show-elevator
              show-sizer
              @on-change="addGoodsChangePage" @on-page-size-change="addGoodsChangePageSize"
              class="mt10"/>
      </Row>
    </Modal>

    <!--便捷添加供应商-->
    <Modal v-model="add_fast.modal" title="便捷添加商品" width="900">
      <div >
        <Form  ref="add_fast" :model="add_fast.item" :label-width="100" :rules="add_fast.ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="12">
              <FormItem label="商品名称" prop="goods_name">
                <Input v-model="add_fast.item.goods_name"  placeholder="请填写商品名称"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="商品类别" prop="category_id">
                <Select v-model="add_fast.item.category_id" placeholder="请选择商品类别">
                  <Option v-for="(option, index) in common.category_options" :value="option.value" :key="index">{{option.label}}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeGoodsFast">关闭</Button>
        <Button type="primary" :loading="add_fast.modal_loading" @click="saveGoodsFast('add_fast')">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {remoteGoodsCategory, getGoodsCategoryList, apiSaveGoodsFast} from '@/api/goods/goodsCategory';
import NP from "number-precision";

export default {
  name: "SelectGoods",
  data() {
    return {
      add_fast:{
        modal_loading: false,
        item: {
          goods_name:'',
          category_id: '',
        },
        modal: false,
        ruleInline:{
          goods_name: [
            {required: true, message: '请填写商品名称'}
          ],
          category_id:[
            {required: true, message: '请选择商品类别'}
          ],
        }
      },
      add_goods: {
        modal: this.select_goods.modal,
        modal_loading: false,
        columns: [
          {
            type: 'selection',
            align: 'center',
            width: 60,
          },
          // {
          //   title: '缩略图',
          //   key: 'thumbnail_url',
          //   slot: 'thumbnail_url',
          //   align: 'center',
          // },
          {
            title: '商品编号',
            key: 'part_no',
            slot: 'part_no',
            width: 100,
            align: 'center',
          },
          {
            title: '商品名称',
            key: 'part_name',
            minWidth:300,
            align: 'left',
          },
          {
            title: '商品类别',
            key: 'category_name',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
        ],
        selected_columns: [
          {
            title: '商品信息',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          },
        ],
        list: [],
        list_total: 0,
        page_size: 0,
        current_page: 1,
        formItem: {
          part_no: '',
          part_name: '',
          category_id: 0,
        },
        selected: [],
        list_loading: false,
      },
      common: {
        category_loading: false,
        category_options: [],
        category_list: []
      }
    }
  },
  props: {
    'select_goods': Object,
  },
  methods: {
    saveGoodsFast(name) {
      this.add_fast.modal_loading = true;
      this.$refs[name].validate((valid) => {
        if (valid) {
          apiSaveGoodsFast(this.add_fast.item).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.add_fast.modal = false;
              this.add_fast.modal_loading = false;
              this.getGoodsList();
            } else {
              this.add_fast.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.add_fast.modal_loading = false;
          })
        } else {
          this.add_fast.modal_loading = false;
        }
      });
    },
    closeGoodsFast() {
      this.add_fast.modal = false;
      this.add_fast.modal_loading = false;
    },
    addGoodsFast(name) {
      this.$refs[name].resetFields();
      this.add_fast.modal_loading = false;
      this.add_fast.modal = true;
    },
    minus(a, b) {
      return NP.minus(a, b)
    },
    remoteGoodsCategory(query) {
      if (query !== '') {
        this.common.category_loading = true;
        remoteGoodsCategory({query:query}).then(response => {
          this.common.category_loading = false;

          const list = response.data.results.map(item => {
            return {
              value: item.id,
              label: item.name
            };
          });

          this.common.category_options = list.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        });
      } else {
        this.common.category_options = this.common.category_list;
      }
    },
    goodsCategoryList() {
      getGoodsCategoryList({size:100}).then(response => {
        this.common.category_list = this.common.category_options = response.data.results.list.map(item => {
          return {
            value: item.id,
            label: item.name
          };
        });
      });
    },
    showGoodsList() {
      this.add_goods.modal_loading = false;
      this.initGoodsSearch('add_goods.formItem');
      this.add_goods.modal = true;
    },
    //获取选中的商品
    addGoodsGetGoodsInfo(selection) {
      if (this.add_goods.selected.length) {
        for (let j = 0; j < selection.length; j++) {
          let add_flag = 0;
          for (let i = 0; i < this.add_goods.selected.length; i++) {
            if (selection[j].id == this.add_goods.selected[i].id) {
              add_flag = 1;
            }
          }

          if (!add_flag) {
            this.add_goods.selected.push(selection[j]);
          }
        }

        //去除删除的
        for (let j = 0; j < this.add_goods.list.length; j++) {
          let delete_flag = 0;
          for (let i = 0; i < this.add_goods.selected.length; i++) {
            if (this.add_goods.list[j].id == this.add_goods.selected[i].id) {
              delete_flag = 1;
              for (let n = 0; n < selection.length; n++) {
                if (this.add_goods.list[j].id == selection[n].id) {
                  delete_flag = 0;
                  break;
                }
              }

              if (delete_flag) {
                this.add_goods.selected.splice(i, 1);
              }
            }
          }


        }
      } else {
        this.add_goods.selected = selection;
      }
    },
    rowClick(row, index) {
      this.$refs.add_goods_list.toggleSelect(index)
    },
    selectedClose(row, index) {
      this.add_goods.selected.splice(index, 1);
      for (let i = 0; i < this.add_goods.list.length; i++) {
        if (row.id == this.add_goods.list[i].id) {
          this.$refs.add_goods_list.toggleSelect(i);
        }
      }
    },
    searchGoodsSubmit() {
      this.add_goods.current_page = 1;
      this.getGoodsList();
      this.goodsCategoryList();
    },
    cancelGoodsSearch(name) {
      this.add_goods.current_page = 1;
      // this.add_goods.selected = [];

      this.$refs[name].resetFields();
      this.getGoodsList();
      this.goodsCategoryList();
    },
    initGoodsSearch(name){
      this.add_goods.current_page = 1;
      this.add_goods.selected = [];

      this.$refs[name].resetFields();
      this.getGoodsList();
    },
    //获取商品列表
    getGoodsList() {
      this.add_goods.list_loading = true;
      let obj = this.add_goods;

      let param = {
        page: this.add_goods.current_page,
        size: this.add_goods.page_size,
        part_no: this.add_goods.formItem.part_no,
        category_id: this.add_goods.formItem.category_id,
        part_name: this.add_goods.formItem.part_name,
        house_id: this.select_goods.house_id !== undefined ? this.select_goods.house_id : 0,
        count_flag: this.select_goods.count_flag,//是否有库存
        client_id: this.select_goods.client_id !== undefined ? this.select_goods.client_id : 0,//是否客户ID
      };
      this.$axios.post('/api/goods/getGoodsList', param).then((response) => {
        this.add_goods.list_loading = false;
        if (response.data.err_no == 0) {
          obj.list = response.data.results.list;
          obj.list_total = response.data.results.total;
          obj.page_size = response.data.results.size;

          for (let i = 0; i < obj.list.length; i++) {
            obj.list[i].thumbnail_url = obj.list[i].certificate[0] !=undefined ? obj.list[i].certificate[0].url : '';
            obj.list[i].part_no = obj.list[i].part_no +  (obj.list[i].part_no_postfix ? ('-' + obj.list[i].part_no_postfix) : '')
            // obj.list[i].cost_price = parseFloat(obj.list[i].cost_price);
            obj.list[i].frozen_stock = parseFloat(obj.list[i].frozen_stock);
            obj.list[i].selling_price = parseFloat(obj.list[i].selling_price);
            obj.list[i].stock_all = parseFloat(obj.list[i].stock_all);

            for (let j = 0; j < this.add_goods.selected.length; j++) {
              if (obj.list[i].id == this.add_goods.selected[j].id) {
                obj.list[i]._checked = true;
              }
            }
          }
        }
      });
    },
    //商品信息分页
    addGoodsChangePage(page) {
      this.add_goods.current_page = page;
      this.getGoodsList();
    },
    //更换每页数量
    addGoodsChangePageSize(pageSize) {
      this.add_goods.page_size = pageSize;
      this.add_goods.current_page = 1;
      this.getGoodsList();
    },
    //设置选中的商品
    addGoodsSetSelectedGoods() {
      let selected_goods = this.add_goods.selected;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          selected_goods[i].sale_money = 0;
          selected_goods[i].sale_num = '';
          selected_goods[i].sale_price = selected_goods[i].selling_price;
        }
      }
      this.$emit('selected', selected_goods);
    },

    onSelectionChange(selection) {
      console.log(selection);
    }
  },
  mounted() {
    this.goodsCategoryList();
  },
}
</script>

<style scoped>
.selected-close {
  cursor: pointer;
}
</style>
<style>
#selected_goods .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}


#selected_filter .ivu-input,#selected_filter .ivu-select-input{
  font-size: 12px !important;
}

#selected_filter .ivu-form-item-label {
  font-size: 12px !important;
}

</style>
